<template>
  <div class="row">
    <q-space />
    <q-btn
      dense
      flat
      icon="close"
      style="color: black; font-size: 12px"
      v-close-popup
      class="q-mt-md q-ml-md q-pr-lg desktop-hide text-right"
    >
    </q-btn>
  </div>
</template>
